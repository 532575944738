@font-face {
  font-family: iAWriter;
  src: url(../public/fonts/iAWriterMonoS-Regular.ttf);
}
@font-face {
  font-family: iAWriter;
  src: url(../public/fonts/iAWriterMonoS-Bold.ttf);
  font-weight: bold;
}
@font-face {
  font-family: iAWriter;
  src: url(../public/fonts/iAWriterMonoS-Italic.ttf);
  font-style: italic;
}
@font-face {
  font-family: iAWriter;
  src: url(../public/fonts/iAWriterMonoS-BoldItalic.ttf);
  font-weight: bold;
  font-style: italic;
}

body {
  background: rgb(250, 240, 216);
}

body #root {
  margin: 0;
  font-family: iAWriter;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  font-variant-ligatures: discretionary-ligatures;
  font-feature-settings: "dlig";
}

.homeLink:hover {
  background-color: #111418;
  color: rgb(255, 252, 225);
}

.homeLink:hover .link {
  color: rgb(255, 252, 225);
}

.homeLink .link {
  transition: all .15s ease-in-out;
  text-decoration: none;
  color: #111418;
  outline: none;
}

.link {
  display: block;
  padding: 30px;
}

.homeLink {
  border: 1px solid black;
  border-radius: 2px;
  margin: 1px solid black;
  transition: all .15s ease-in-out;
  cursor: pointer;
  text-align: center;
}

/* hide ">" crumb separator */
.bp4-overflow-list.bp4-breadcrumbs > li:after {
  content: none;
}
.bp4-breadcrumb .bp4-icon.bp4-icon-slash {
  margin-right: 8px;
}
.bp4-breadcrumbs > li {
  margin-right: 4px; /* this matches the left "/" icon margin */
  margin-left: 4px; /* this matches the left "/" icon margin */
}

.bp4-breadcrumbs > li:first-child {
  margin-left: 0px;
}

.bp4-breadcrumbs > li:last-of-type .bp4-breadcrumb {
  color: #111418;
}

li > .bp4-breadcrumb {
  color: #5f6b7c;
  text-decoration: none;
}
li > a.bp4-breadcrumb:hover {
  color: inherit;
}
