.hoverable:hover {
  background-color: #f4d06f;
}

.hoverable {
  text-decoration: none;
  transition: all .15s ease-in-out;
}

.horiz:after {
  background: #121212;
  content: "";
  display: block;
  height: 1px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  box-sizing: border-box;
}

.highlighted-content {
  background-color: #f4d06f;
}
